// Label Reset 
.hm-label {
    width: 100%;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
}

// Input Reset 
.hm-text-input {
    outline: 0;
    font-weight: 400;
    line-height: var(--font-line-height-body);
    font-size: 1em;
    border: 1px solid WindowFrame;
    box-sizing: border-box;

    &:focus {
        border-color: var(--color-primary);
        outline: 0.125em solid var(--color-primary);
        outline-offset: 0.125em;
        box-shadow: var(--focus-box-shadow);
        border-radius: 0.25em;
    }
}

// Input Wrapper
.hm-input-wrapper--default-theme {
    &:focus-within {
        label {
            opacity: 1 !important;
            color: var(--color-primary);
        }
    }

    input {
        width: 100%;
    }
}

// Label Themed 
.hm-label--default-theme {
    padding: 0 0 0.375em; // 3/8 
}

// Input Themed 
.hm-text-input--default-theme {
    height: 3.5em;
    padding: 1.125rem;
    line-height: 1.125em;
    border-radius: 0.25rem; // 1/4
    
    transition:
        outline var(--animation-step) ease-in-out,
        transform var(--animation-step) ease-in-out,
        box-shadow calc(var(--animation-step)*2) ease-in-out,
        border-color var(--animation-step) ease-in-out,
        background-color var(--animation-step) ease-in-out;

    box-shadow: 0 0 0em 2em transparent;

    &::placeholder {
        font-weight: 300;
        color: var(--color-foreground);
    }

    &:focus {
        border-color: transparent;
        // border-color: var(--color-primary);
        outline: 0.25em solid var(--color-primary);
        outline-offset: 0.0675em;
        box-shadow: var(--focus-box-shadow);
    }

    // Size
    &[data-size="sm"],
    &.--sm {
        padding: 0.375rem 0.75rem;
        height: 2.25em;
    }
}

// Inner Label Variant
.hm-input-wrapper--inner-label {
    position: relative;

    .hm-label {
        font-weight: 400;
        position: absolute;
        top: 1rem;
        left: 1.1375rem;
        font-size: 0.975em;
        opacity: 0.875;
        pointer-events: none;
        z-index: 2;
        transform-origin: top left;
        transition:
            transform calc(var(--animation-step)/2) ease-in-out,
            opacity calc(var(--animation-step) *2) ease-in-out;

    }

    .hm-text-input {
        height: 4.5em;
        padding: 2.25rem 1.125rem 0.875rem;
        position: relative;
        z-index: 1;

        &::placeholder {
            opacity: 0.4;
            color: var(--color-foreground);
        }

        &:focus {

            &::placeholder {
                opacity: 0.25;
            }
        }
    }

    &.hm-input--filled {
        .hm-label {
            opacity: 0.875 !important;
            // transform: scale(0.95);
        }
    }

    &:focus-within {
        label {
            opacity: 1 !important;
            color: var(--color-primary);
        }
    }
}

// Input Error
.hm-input-wrapper {

    &.--error {
        label {
            color: var(--color-red);
        }

        .hm-form-helper {
            color: var(--color-red);
        }

        input,
        textarea {
            border-color: var(--color-red);
            color: var(--color-red-lm2);
        }

        &:focus-within {
            label {
                opacity: 1 !important;
                color: var(--color-red);
            }
        }
    }
}

// .. 
.hm-text-input__invalid-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    fill: var(--color-red);
    transform: translateY(-50%);
}

// Form Wrapper 
.hm-form-helper {
    font-size: 0.875em;
    margin-top: 0.25em;
    display: block;
}
