
// Reset
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: unset;
    margin: 0 0 1em;
    line-height: var(--font-line-height-body);
    font-weight: 800;
}

p {
    margin: 0 0 1em;
    font-weight: 300;
    line-height: var(--font-line-height-body);
}

// Sizing
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
span {
    &[data-heading="xxl" i],
    .--xxl {
        font-size: clamp(1.75em, 8vw, 8em);
        line-height: 1.05em;
        letter-spacing: -0.0375em;
        margin-bottom: 0.375em;
    }

    &[data-heading="xl" i],
    &.--xl {
        font-size: clamp(2em, 6.5vw, 5em);
        line-height: 1.125em;
        letter-spacing: -0.01em;
        margin: 0 0 0.5em;
    }

    &[data-heading="lg" i],
    &.--lg {
        font-size: clamp(2em, 3vw, 3em);
        line-height: var(--font-line-height-heading);
        letter-spacing: 0em;
        margin: 0 0 1em;
    }

    &[data-heading="md" i],
    &.--md {
        font-size: 1.25em;
        line-height: var(--font-line-height-body);
        margin: 0 0 1em;
    }

    &[data-heading="sm" i],
    &.--sm {
        font-size: 1em;
        line-height: var(--font-line-height-body);
        margin: 0 0 1em;
    }

    &[data-heading="xs" i],
    &.--xs {
        font-size: 0.875em;
        line-height: var(--font-line-height-body);
        letter-spacing: 0.01em;
        font-weight: 600;
        margin: 0 0 1em;
    }
}

p {
    max-width: 38em;
}




.--inverted {
    background: var(--color-primary) !important; //This color is only meant to be used for this guide. We would change this to $bgColor;
    color: var(--color-background);

    .muted,
    .lightText {
        color: var(--color-background);
        opacity: 0.6;
    }

    // Adjust letter-spacing for light text on a dark background.
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    span {
        &[size="xs" i] {
            font-weight: 400;
            letter-spacing: 0.03em;
        }
    }
    p {
        letter-spacing: 0.01em;
        font-weight: 300;
    }
}

a {
    color: var(--color-primary);
    transition: transform calc(var(--animation-step) / 2) ease-in-out;

    &:hover {
        color: var(--color-primary-lm2);
    }
}