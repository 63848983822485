// Default Button reset


.hm-button {
    display: flex;
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    font-size: var(--font-size);
    font-weight: 400;
    font-family: inherit;
    line-height: var(--font-line-height-body);
    cursor: pointer;
    text-align: center;
    align-items: center;
    overflow: visible;
    text-transform: none;
    outline: 0;
    border: var(--border-width) solid transparent;
    padding: 0;

    abbr {
        margin-left: 0.675em;
        width: 1em;
        height: 1em;
        pointer-events: none;

        &.iconExample {
            svg {
                width: 100%;
                transform-origin: center;
            }
        }
    }

    &:focus-visible {
        // outline-offset: 0.25em;
        outline-color: purple;
        box-shadow: var(--focus-box-shadow);
    }

    &:focus:not(:focus-visible) {
        outline: none;
        box-shadow: none;
    }

    &:focus:not(:-moz-focusring) {
        outline: none;
        box-shadow: none;
    }

    &:active {
        transform: scale(0.97);
    }
}

.hm-button--dope {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color-background);
    background-color: var(--color-foreground);
    font-weight: 600;
    letter-spacing: 0.01em;
    transition: transform var(--animation-step) ease-in-out,
        box-shadow var(--animation-step) ease-in-out,
        background-color var(--animation-step) ease-in-out;
    padding: 1.125em;
    line-height: 1em;
    border-radius: 0em;
    text-align: center;
    font-size: var(--font-size);
    text-decoration: none !important;


    span {
        width: 100%;
    }

    &:hover {
        background-color: var(--color-primary);
    }


    &:active {
        outline: 0;
        box-shadow: 0 0 0 calc(0.25em) var(--color-primary);
    }

    &.--hm-publish {
        &:focus {
            border-color: var(--color-primary);
            background-color: var(--color-primary);
            outline-offset: 0em !important;
            outline: 0.375em solid var(--color-primary) !important;
        }
    }
}

// Sizes
.hm-button,
button,
a {

    // Sizes
    &[data-size="xs"],
    &.--xs {
        padding: 0.675em 0.75em;
        font-size: 1em;
        font-weight: 400;

        &.--round {
            padding: 0.675em 1.35em;
        }

        &.--circle {
            padding: 0.675em 0.875em;
        }
    }

    &[data-size="sm"],
    &.--sm {
        padding: 0.5em 0.75em;
        font-size: 1em;
        font-weight: 400;

        &[data-round],
        &.--round {
            padding-left: 0.875em;
            padding-right: 0.875em;
        }
    }

    &[data-size="md"],
    &.--md {
        padding: 1.125em 1.5em;
    }

    &[data-size="lg"],
    &.--lg {
        padding: 1.375em 1.5em;
        font-size: 1.125em;
    }

    &[data-size="xl"],
    &.--xl {
        padding: 1.25em;
        font-size: 1.375em;
        line-height: 1.125em;
        font-weight: 800;
    }

    // Variants
    // Variants
    &[data-variant="primary"],
    &.--primary {
        background-color: var(--color-primary);
        color: var(--color-background);
        transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3), box-shadow calc(var(--animation-step) * 2) ease-in-out, transform calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3);

        &:hover {
            color: var(--color-background);
            background-color: var(--color-primary);
            transform: scale(1.0125);
        }

        &:focus:not(:focus-visible) {
            outline: none;
            box-shadow: none;
        }

        &:focus:not(:-moz-focusring) {
            outline: none;
            box-shadow: none;
        }

        // &[data-variant="outline"],
        // &.--outline {
        //     background-color: transparent;
        //     color: var(--color-foreground);
        //     border-color: var(--color-foreground);
        //     box-shadow: 0 0 0 1px var(--color-foreground) !important;

        //     &:hover,
        //     &:focus {
        //         color: var(--color-foreground);
        //     }

        //     &:active {
        //         color: var(--color-foreground);
        //     }
        // }
    }

    &[data-variant="secondary"],
    &.--secondary {
        background-color: var(--color-secondary);
        color: var(--color-background);
        border-color: var(--color-secondary);
        box-shadow: 0 0 0em 0px var(--color-tertiary);

        &:hover {
            color: var(--color-background);
            background-color: var(--color-foreground);
            border-color: var(--color-foreground);
        }

        &:active {
            box-shadow: 0 0 1.5em 1px var(--color-tertiary) !important;
            border-color: var(--color-tertiary);
        }

        // &[data-variant="outline"],
        // &.--outline {
        //     background-color: transparent;
        //     color: var(--color-foreground);
        //     border-color: var(--color-foreground);
        //     box-shadow: 0 0 0 1px var(--color-foreground) !important;

        //     &:hover,
        //     &:focus {
        //         color: var(--color-foreground);
        //     }

        //     &:active {
        //         color: var(--color-foreground);
        //     }
        // }
    }

    &[data-variant="tertiary"],
    &.--tertiary {
        background-color: var(--color-secondary);
        color: var(--color-primary-lm6);
        box-shadow: 0 0 0em 0px var(--color-secondary-a05);

        &:hover {
            color: var(--color-primary-lm6);
            background-color: var(--color-secondary-lp2);
            box-shadow: 0 0 0.75em 1px var(--color-secondary-a02);
        }

        &:active {
            color: var(--color-primary-lm6);
            background-color: var(--color-secondary-lp2);
            box-shadow: 0 0 1.5em 1px var(--color-secondary-a04) !important;
        }

        // &[data-variant="outline"],
        // &.--outline {
        //     background-color: transparent;
        //     color: var(--color-secondary);
        //     border-color: var(--color-secondary);
        //     box-shadow: 0 0 0 1px var(--color-secondary) !important;

        //     &:hover,
        //     &:focus {
        //         color: var(--color-primary-lm6);
        //         background-color: var(--color-secondary);
        //         box-shadow: 0 0 0.75em 1px var(--color-secondary-a02);
        //     }

        //     &:active {
        //         color: var(--color-primary-lm6);
        //         background-color: var(--color-secondary-lp2);
        //         box-shadow: 0 0 1.5em 1px var(--color-secondary-a04) !important;
        //     }
        // }
    }

    &[data-variant="outline"],
    &.--outline {
        background-color: transparent;
        color: var(--color-foreground);
        border-color: var(--color-foreground);
        border-width: var(--border-width);

        &:hover,
        &:focus {
            color: var(--color-background);
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }

        &[data-variant="inverted"],
        &.--inverted {
            background-color: transparent;
            color: var(--color-foreground);
            border-color: var(--color-foreground);

            &:hover,
            &:focus {
                color: var(--color-background);
                background-color: var(--color-foreground);
            }
        }
    }

    &[data-variant="ghost"],
    &.--ghost {
        background-color: transparent;
        color: var(--color-primary);
        border-color: transparent !important;

        &:hover,
        &:focus {
            color: var(--color-foreground-secondary) !important;
            background-color: var(--color-background-secondary) !important;
        }
    }

    &.--wide {
        width: 100%;
        text-align: center;
    }

    &.--mw {
        width: clamp(13em, 85%, 30em);
        margin: 0 auto;
    }

    &.--flex {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &[data-round],
    &.--round {
        border-radius: 1111em;
        padding-left: 1.375em;
        padding-right: 1.375em;
    }

    // &[data-variant="danger"] {
    //     background-color: $redColor;
    //     color: $bgColor;

    //     &:hover {
    //         color: $bgColor;
    //         background-color: mix($bodyColor, $redColor, 30%);
    //     }

    //     &:focus {
    //         color: $bgColor;
    //         background-color: mix($bodyColor, $redColor, 10%);
    //     }
    // }

    &[disabled] {
        background-color: transparent !important;
        color: var(--color-foreground--l60) !important;
        cursor: not-allowed;
        box-shadow: none !important;

        &:hover,
        &:active,
        &:focus {
            color: var(--color-foreground--l90) !important;
            transform: none;
        }
    }
}

// Icon Only
.hm-button--icon-only {
    text-align: center;
    padding: 0.25em;

    abbr {
        margin: 0 auto;
        line-height: auto;
        width: 100%;
        height: 100%;

        svg {
            width: 100%;

        }
    }

    &[data-size="sm"] {
        height: 2.25em;
        width: 2.25em;
        padding: 0.375em;
    }

    &[data-size="md"] {
        height: 3.5em;
        width: 3.5em;
        padding: 0.75em;
    }

    &[data-size="lg"] {
        height: 4em;
        width: 4em;
        padding: 0.75em;
    }

    &.--xl,
    &[data-size="xl"] {
        height: 4em;
        width: 4em;
        padding: 1em;
    }

    &[data-round] {
        text-align: center;


    }
}

:root {
    --button-active-color: var(--color-foreground);
}

/* Default Button Switch */
button.hm-button__switch {
    background-color: var(--color-background);
    border: 1px solid var(--color-foreground);
    border-radius: 10em;
    display: block;
    padding: 0.25em;
    white-space: nowrap;
    transition: background-color var(--animation-step) ease-in-out, border-color var(--animation-step) ease-in-out;
    backdrop-filter: blur(0.25em);

    & span {
        font-size: 0.875em;
        letter-spacing: 0.05em;
        pointer-events: none;
        padding: 0.25em;
        width: 3em;
        border-radius: 10em;
        display: inline-block;
        line-height: var(--font-line-height-body);
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        background-color: transparent !important;
        transition: color calc(var(--animation-step) * 1.5) ease-in-out;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background-color: color-mix(in srgb, var(--color-foreground) 20%, transparent 95%);
            top: 0;
            left: 0;
            border-radius: 111em;
            z-index: -2;
            transition: transform calc(var(--animation-step) * 2) cubic-bezier(0, -0.25, 0.5, 1.25), background-color var(--animation-step) ease-in-out;
        }

        & abbr {
            position: absolute;
            left: -100%;
            margin: 0.375em 0em 0 0.2675em;
            transition: opacity calc(var(--animation-step) / 2) ease-in-out 0;
            opacity: 0;

            & svg {
                width: 175%;
                height: auto;
                overflow: visible;

                & path {
                    fill: none;
                    stroke-width: 1.75px;
                    stroke: var(--color-background);
                    transition: stroke-dashoffset calc(var(--animation-step) * 1.5) ease calc(var(--animation-step) * 1.25);
                    stroke-dasharray: 20;
                    stroke-dashoffset: 20;
                }
            }
        }

    }

    &:active {
        transform: none;
    }


    &[role="switch"][aria-checked="false"] :first-child,
    &[role="switch"][aria-checked="true"] :first-child {
        background-color: transparent !important;
        color: inherit;
        z-index: 2;

        &:before {
            display: none;

        }
    }


    &[role="switch"][aria-checked="true"] :last-child {
        color: var(--color-foreground);

        &:before {
            background-color: var(--color-foreground);
            border-color: var(--color-foreground);
            box-shadow: 0 0 0.5em color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
        }

        & abbr {
            opacity: 1;
            transition: opacity calc(var(--animation-step) / 2) ease-in-out calc(var(--animation-step) * 1.25);

            & svg {
                width: 100%;
                transform: scale(1.35) translate(0.41em, -0.05em);

                & path {
                    stroke-dasharray: 20;
                    stroke-dashoffset: 0;
                    stroke-width: 2px;
                    stroke-linecap: round;
                }
            }
        }
    }

    &[role="switch"][aria-checked="false"] :last-child {
        color: color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
        
        &:before {
            transform: translateX(calc(-100% - 0.275em));
        }


    }

    &[role="switch"][aria-checked="true"] :first-child {
        color: color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
    }

    &[role="switch"][aria-checked="true"] :last-child {
        color: var(--color-background);
    }

    &:focus {
        outline: 0.25em solid color-mix(in srgb, var(--color-primary) 50%, transparent 95%);
        outline-offset: 0.125em;
        box-shadow: var(--focus-box-shadow);

    }

    &:focus:not(:focus-visible) {
        outline: none;
        box-shadow: none;
    }

    &:focus:not(:-moz-focusring) {
        outline: none;
        box-shadow: none;
    }
}

label.hm-label__switch {
    user-select: none;
    cursor: pointer;
    padding: 0 0.5em 0 0;
    opacity: 0.6;
    font-weight: 300;

    &:hover {
        opacity: 1;
    }
}

/* Button Switch SM & XS */
button.hm-button__switch.switch--sm {
    font-size: 0.75em;
    background-color: color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
    border: 1px solid color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);

    & span {
        color: transparent !important;
        width: 2.5em;
        height: 2.5em;

        &:before {
            background-color: color-mix(in srgb, var(--color-background) 100%, transparent 0%);
            transition: transform calc(var(--animation-step) * 1.25) cubic-bezier(0, -0.25, 0.5, 1), background-color var(--animation-step) ease-in-out;
        }
    }

    &[role="switch"][aria-checked="false"] :last-child {

        &:before {
            transform: translateX(calc(-100% - 0.25em));
            background-color: var(--color-background) !important;
        }

    }

    &[role="switch"][aria-checked="true"] {
        background-color: var(--button-active-color);
        border-color: var(--button-active-color);


        & span {
            &:before {
                background-color: var(--color-background);
                border-color: var(--color-background);

            }
        }
    }
}

button.hm-button__switch.switch--xs {
    font-size: 0.5em;
    padding: 0.5em;
    background-color: color-mix(in srgb, var(--color-foreground) 40%, transparent 95%);
    border: 0px solid color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);

    & span {
        color: transparent !important;
        width: 2.5em;
        height: 2.5em;

        &:before {

            transition: transform calc(var(--animation-step) * 1.25) cubic-bezier(0, -0.25, 0.5, 1), background-color var(--animation-step) ease-in-out;
        }
    }

    &[role="switch"][aria-checked="false"] :last-child {

        &:before {
            background-color: var(--color-background) !important;
            transform: translateX(calc(-100% - 0.25em));
        }

        & span {
            &:before {}
        }
    }

    &[role="switch"][aria-checked="true"] {
        background-color: var(--button-active-color);
        border-color: var(--button-active-color);
        // box-shadow: 0 0 2em 0 color-mix(in srgb, var(--button-active-color) 40%, transparent 95%);


        & span {
            &:before {
                background-color: var(--color-background);
                border-color: var(--color-background);

            }
        }
    }
}


// Menu Button Example
button.menuButtonExample {
    padding: 0.25em;

    &:active {
        transform: none;
    }

    abbr {
        width: 1.5em;
        height: 1.5em;
        position: relative;
        margin: 0;
        pointer-events: none;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: var(--color-foreground);
            position: absolute;
            top: calc(50% - 0.25em);
            left: 0;
            transition: transform calc(var(--animation-step) * 2) ease-in-out;
        }

        &:after {
            top: calc(50% + 0.25em);
            transition: transform calc(var(--animation-step) * 2) ease-in-out;
        }
    }

    &[aria-expanded="true"] {
        abbr {
            &:before {
                transform: translateY(+0.25em) rotate(-225deg);
            }

            &:after {
                transform: translateY(-0.25em) rotate(225deg);
            }
        }
    }

    &[aria-expanded="false"] {
        abbr {
            &:before {
                transform: translateY(0) rotate(0deg);
            }

            &:after {
                transform: translateY(0) rotate(0deg);
            }
        }
    }

}

button.toggle__l00k__nav {
    abbr.closeIcon {
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        margin: 0;
        pointer-events: none;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: var(--color-foreground);
            position: absolute;
            top: calc(50% - 0.25em);
            left: 0;
            transition: transform calc(var(--animation-step) * 2) ease-in-out;
            transform: translateY(+0.25em) rotate(-225deg) scaleX(0);
        }

        &:after {
            top: calc(50% + 0.25em);
            transition: transform calc(var(--animation-step) * 2) ease-in-out;
            transform: translateY(-0.25em) rotate(225deg) scaleX(0);
        }
    }

    &[aria-expanded="true"] {

        span {
            opacity: 0;
        }

        abbr.closeIcon {
            &:before {
                transform: translateY(+0.25em) rotate(-225deg) scaleX(1);
            }

            &:after {
                transform: translateY(-0.25em) rotate(225deg) scaleX(1);
            }
        }
    }

    &[aria-expanded="false"] {

        span {
            opacity: 1;
        }

        abbr.closeIcon {
            &:before {
                transform: translateY(+0.25em) rotate(-225deg) scaleX(0);
            }

            &:after {
                transform: translateY(-0.25em) rotate(225deg) scaleX(0);
            }
        }
    }
}