// App
@import "vars";
@import "button";
@import "input";
@import "type";
// @import "panels";



// FavIcon
path.favIcon {
    fill: black;
}

@media (prefers-color-scheme: dark) {
    path.favIcon {
        fill: white;
    }
}





// Reset
:root {
    font-size: 16px;
    line-height: 1.375em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    font-weight: 400;
}

html {
    background: var(--color-background);
    color: var(--color-foreground);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: var(--font-family);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

html,
body {
    font-size: 16px;
    position: relative; // Safari Hack (blerg!)
    
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: inherit;
}

body,
main,
article {
    padding: 0;
    margin: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
}

main,
header,
.l00k__user-heading {
    // max-width: 60em;
    margin: 0 auto;
}

// Prefer This
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

main {
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;
}





// 
nav.hm__nav {
    animation-duration: var(--animation-step);
    display: grid;
    align-items: center;
    padding: var(--space) var(--space) 0;
    grid-template-columns: repeat(auto-fit, minmax(6.5em, 0.25fr));
    justify-content: space-between;
    font-family: var(--font-mono);
    margin: 1em auto;
    text-transform: uppercase;
    gap: 0.25em;

    h1,
    h2,
    h3 {
        margin: 0;
        font-size: 3.5em;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1 {
        font-size: 1rem;
        background-color: var(--color-foreground);
        color: var(--color-background);
        letter-spacing: 0.125em;
        text-transform: uppercase;

    }


    a,
    h1,
    .hm-button {
        @extend .hm-button, .hm-button--dope, .--sm;
        background-color: var(--color-foreground);
        color: var(--color-background);
        letter-spacing: 0.125em;
        text-transform: uppercase;
        border-width: var(--border-width);
        font-weight: 100;
        
    }


    // .hm__login {
    //     display: inline-grid;
    //     grid-template-columns: auto auto;
    //     gap: 1em;

    //     span {
    //         opacity: 0.75;
    //     }

    //     form {
    //         padding: 0;

    //         button {
    //             margin: 0 0 0 0.25em;
    //             text-transform: uppercase;
    //             letter-spacing: 0.125em;
    //             font-size: clamp(0.75em, 2vw, 2em); 
    //         }
    //     }
    // }

    .hm__user-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    form {
        padding: 0;

        button {
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0.125em;
            width: 100%;
            
        }
    }

}