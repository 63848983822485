:root {
    // C0L0R
    --color-foreground: hsla(0, 72%, 11%, 1);
    --color-background: hsl(0, 0%, 99%);

    --color-primary: rgb(143, 0, 252);
    --color-secondary: hsl(109, 94%, 52%);
    --color-tertiary: hsl(282, 99%, 56%);
    --color-quartinary: hsla(224, 16%, 28%, 1);
    
    --color-black: #000;
    --color-white: #fff;
    --color-red: hsla(350, 100%, 60%, 1);
    --color-green: hsla(111, 74%, 51%, 1);
    --color-yellow: hsl(53, 100%, 50%);
    --color-green--a05: hsla(111, 74%, 61%, 0.75);
    --color-green--a025: hsla(111, 74%, 61%, 0.125);
    --color-shadow: rgba(226, 223, 217, 0.3);;
    --color-p: hsla(218, 27%, 18%, 1);

    --color-foreground--l95: hsla(20, 32%, 96%, 1);
    --color-foreground--l90: hsla(20, 32%, 93%, 1);
    --color-foreground--l80: hsla(20, 32%, 81%, 1);
    --color-foreground--l60: hsla(20, 22%, 71%, 1);
    // --color-foreground--l90: hsla(20, 2%, 93%, 1);
    // --color-foreground--l80: hsla(20, 2%, 81%, 1);
    // --color-foreground--l60: hsla(20, 2%, 71%, 1);

    // VARS
    --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    --font-mono: monospace, monospace;

    --font-size: 16px;
    --font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    --font-line-height-body: 1.375em;
    --font-line-height-heading: 1.25em;
    --font-line-height-lhm-heading: 1.125em;
    --space: calc(var(--font-size) * 1.75);
    --screen-xl: 1500px;
    --screen-lgg: 1260px;
    --screen-lg: 1060px;
    --screen-md: 800px;
    --screen-sm: 480px;
    --animation-step: 0.125s;
    --input-box-shadow: 0 0 0em 1em var(--foreground-color) ;
    --focus-box-shadow: 0 0 0 1.375em var(--primary-color) ;
    --focus-box-shadow-color: var(--primary-color);
    --border-width: 1px;
    --frame: var(--color-primary-lp1);

    --space: calc(var(--font-size) * 1.5);
    // --border-radius: calc(14px * 1);
    --border-radius: calc(var(--space) * 1);


    // xs
    @media (min-width: 400px) {
      --space: calc(var(--font-size) * 1.5); // = 16px
      // --border-radius: calc(var(--space) * 0.875);
      // --border-radius: calc(14px * 1);
    }
    // sm
    @media (min-width: 800px) {
        --space: calc(var(--font-size) * 1.875); // 
    }
    // md
    @media (min-width: 1060px) {
      --space: calc(var(--font-size) * 2); // = 40px
    }
    // @media (min-width: 1500px) {
    //   --space: calc(var(--font-size) * 3.5); // = 56px
    // }
    // @media (min-width: 1800px) {
    //   --space: calc(var(--font-size) * 4); // = 64px
    // }

    // Column-specific colors
    --color-column-1: hsla(39, 8%, 69%, 1);    /* #B8B4AB */
    --color-column-2: hsla(35, 9%, 73%, 1);    /* #BFBBB4 */
    --color-column-3: hsla(40, 11%, 82%, 1);   /* #D6D4CE */
    --color-column-4: hsla(39, 8%, 70%, 1);    /* #B9B5AD */
    --color-column-5: hsla(39, 9%, 66%, 1);    /* #AFAAA1 */
    
    // New column gradient and accent colors
    --color-column-dark: hsla(110, 90%, 20%, 1);       /* #010101 */
    --color-column-accent: hsla(243, 57%, 60%, 1);  /* #D4B95F */
    --color-column-highlight: hsla(17, 68%, 55%, 1); /* #D9723E */
}